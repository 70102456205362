// Here you can add other styles


.loadMoreRowsClassname {
    width      : 180px;
    padding    : 8px 16px;
    position   : absolute;
    bottom     : 8px;
    right      : 8px;
    color      : white;
    line-height: 35px;
    background : rgb(0 0 0 / 0.6);
}

.t_height_78vh {
    width : 100%;
    height: 78vh;
}

.t_height_80vh {
    width : 100%;
    height: 80vh; 
    overflow-y: auto;   
}

.overflow_y_auto {
    overflow-y: auto;
}

.text_align_center {
    text-align: center;
}

.text_align_left {
    text-align: left;
}

.text_align_right {
    text-align: right;
}

.dark_gray_color {
    color: #27303b;
}

.no_underline_link:link{
    text-decoration: none;    
}

.no_underline_link:hover{
    text-decoration: underline;    
}


.workable-content {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 50px;;
}

.overflow-scrollable {
    height: 85%;
    overflow: auto;
}

.rootClassname {
    display       : flex;
    flex-direction: column;
    height        : 100%;
    gap           : 10px;

    >.rdg {
        flex: 1;
    }
}

.toolbarClassname {
    text-align: end;
}

.filter-cell {
    line-height: 35px;
    padding    : 0;

    >div {
        padding: 0 8px;

        &:first-child {
            border-bottom: 1px solid var(--border-color);
        }
    }
}

.filterClassname {
    width    : 100%;
    padding  : 4px;
    font-size: 14px;
}

.survey_answer_tab_pane {
    padding: 10px 15px;    
    border: #ccc;
    border-style: solid;   
    border-top-width: 0; 
    border-left-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.width-200 {
    width: 200px;
}

.inline-block {
    display: inline-block;
}

.inline {
    display: inline;
}

.align-right {
    text-align: right;
}